import { motion } from "framer-motion";
import { div } from "framer-motion/client";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import { useState } from "react";
import DynamicImage from "@/components/DynamicImage";

const manage = [
  {
    title: "营销获客",
    desc: "渠道引流，持续获取新客户",
    items: [
      {
        title: "渠道活码",
        description: "多渠道智能加粉，公域引流私域",
      },
      {
        title: "来源分析",
        description: "来源分析",
      },
    ],
  },

  {
    title: "客户管理",
    desc: "客户管理+客户动态轨迹追踪",
    items: [
      {
        title: "客户画像",
        description: "建立多维度画像，全生命周期管理",
      },
      {
        title: "客户阶段",
        description: "自定义客户所处阶段，赋能销售，促进成交",
      },
      {
        title: "数据分析",
        description: "通过数据化分析，智能洞察客户行为偏好",
      },
    ],
  },

  {
    title: "智能运营",
    desc: "私域运营最佳策略，跟进客户更高效",
    items: [
      {
        title: "客户群发",
        description: "内容互动，一键群发所有客户",
      },
      {
        title: "智能雷达",
        description:
          "智能记录客户访问动作，客户轨迹追踪+智能提醒，及时跟进重点商机",
      },
      {
        title: "客户SOP",
        description: "可创建自动化客户跟进SOP，员工一键发送，高效服务客户",
      },
    ],
  },

  {
    title: "数据风控",
    desc: "多维度数据风控，防止员工带走客户",
    items: [
      {
        title: "离职继承",
        description: "员工离职，客户一键继承，防止员工带走客户",
      },
      {
        title: "流失提醒",
        description: "客户删除员工提醒，及时挽回客户",
      },
      {
        title: "会话存档",
        description: "会话内容云端存档，满足企业合规审计要求",
      },
    ],
  },
];

const Manages = () => {
  const [active, setActive] = useState(1);
  const urlDomain =
    "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/manage";
  return (
    <section
      id="Solutions"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/manage/bg.png')] w-full bg-cover flex justify-center"
    >
      <div className="container w-[1076px] pb-16">
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-4xl font-medium text-black/85">
            智能管理，驱动业绩增长
          </h2>
        </motion.div>

        <div className="flex justify-center flex-wrap mt-12">
          <div className="flex justify-center gap-x-[84px] relative">
            {manage.map((item, index) => (
              <div
                className={`text-xl w-[80px] font-medium ${active === index + 1 ? "text-themeColor" : "text-black/45"}`}
                key={index}
                onMouseEnter={() => setActive(index + 1)}
              >
                {item.title}
              </div>
            ))}
            <div
              className={`bg-themeColor h-0.5 w-[80px] absolute top-[42px] left-0 transition-all duration-700`}
              style={{ transform: `translate(${(active - 1) * 164}px, 0px)` }}
            ></div>
          </div>
          <div className="mt-4 h-px bg-black/10 w-full"></div>
        </div>
        <div className="w-full flex">
          <div className="pt-[73px] pl-6 flex-1">
            <div className="relative h-6">
              <span className="text-xl text-black/85 font-medium z-10 absolute">
                {manage[active - 1].desc}
              </span>
              <DynamicImage
                src={`${urlDomain}/line.png`}
                width={98}
                height={8}
                className="absolute bottom-[-3px] z-0 left-[-3px]"
                alt={manage[active - 1].title}
              ></DynamicImage>
            </div>
            {manage[active - 1].items.map((item, index) => (
              <div className="mt-4 block w-full" key={index}>
                <DynamicImage
                  src={`${urlDomain}/item${active}/icon${index + 1}.png`}
                  width={30}
                  height={30}
                  alt={item.title}
                  className="float-left"
                ></DynamicImage>
                <div className="flex flex-wrap pl-2">
                  <p className="text-base text-black/85 font-bold mt-1 w-full">
                    {item.title}
                  </p>
                  <p className="mt-2 text-sm text-black/65">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
            <div className="flex items-center gap-x-4 mt-[140px]">
              <div className="flex items-center justify-center bg-themeColor shadow-orange-100 rounded-s-sm w-[140px] h-[52px] border border-themeColor text-lg font-medium text-white">
                立即免费使用
              </div>
              <div className="flex items-center justify-center bg-white shadow-black-100 rounded-s-sm w-[140px] h-[52px] border border-themeColor text-lg font-medium text-themeColor">
                立即咨询
              </div>
            </div>
          </div>
          <DynamicImage
            src={`${urlDomain}/item${active}/preview.png`}
            width={696}
            height={535}
            alt={manage[active - 1].desc}
          ></DynamicImage>
        </div>
      </div>
    </section>
  );
};

export default Manages;

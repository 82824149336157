"use client";
import Link from "next/link";
import { Menu, X } from "lucide-react";
import Image from "next/image";
import { useScroll } from "ahooks";
import DynamicImage from "@/components/DynamicImage";
const Header = () => {
  const scroll = useScroll();
  return (
    <header
      className={`fixed w-full top-0 z-50 ${scroll && scroll.top > 0 ? "bg-white/90 shadow-sm" : ""}`}
    >
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center">
          <Link href="/" className="flex items-center">
            <DynamicImage
              src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/logo.png"
              alt="微友智汇"
              width={157}
              height={41}
            />
          </Link>
          <div className="flex justify-center items-center ml-4 gap-x-1 w-[138px] h-[30px] border border-[#0082F0] rounded-s">
            <DynamicImage
              src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/qwLogo.png"
              alt="企微"
              width={20}
              height={18}
            ></DynamicImage>
            <span className="text-sm  text-[#0082f0]">企业微信服务商</span>
          </div>
          <div className="flex ml-auto items-center space-x-4">
            <button className="border bg-white w-[68px] h-9 text-[#FF6700] text-base flex items-center justify-center border-[#FF6700] hover:text-orange-600 rounded-sm">
              登录
            </button>
            <button className="w-[122px] h-9 flex items-center justify-center bg-[#FF6700] text-base text-[#FFFFFF] px-4 py-2 rounded-sm hover:bg-orange-600">
              免费试用
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

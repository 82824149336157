import { MessageSquare } from "lucide-react";

export default function JsonLd() {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "微友智汇SaaS平台",
    applicationCategory: "BusinessApplication",
    operatingSystem: "Web",
    description:
      "打造一站式企业服务平台，整合办公、沟通、协作，让企业运营更高效",
    offers: {
      "@type": "AggregateOffer",
      priceCurrency: "CNY",
      lowPrice: "299",
      highPrice: "699",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.8",
      ratingCount: "1250",
    },
    featureList: [
      "智能消息管理",
      "团队协作",
      "安全可靠",
      "自动化工作流",
      "数据分析",
      "全球化支持",
    ],
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
}

import { MessageSquare } from "lucide-react";
import Link from "next/link";

const Footer = () => {
  return (
    <footer className="bg-black/90 text-white py-[43px] w-full">
      <div className="flex justify-center gap-4">
        <p className="text-sm text-white font-medium">
          深圳市南山区粤海街道滨海社区高新南十道81、83、85号深圳市软件产业基地1栋A701-5
        </p>
        <p className="text-sm text-white font-medium">粤ICP备00000000号</p>
      </div>
    </footer>
  );
};

export default Footer;

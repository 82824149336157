import { motion } from "framer-motion";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";

const pain = [
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/item/icon1.png",
    title: "客户获取难、引流成本高",
    description: "获客难，获客渠道有限，用户属于公域平台，二次引流成本高",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/item/icon2.png",
    title: "触达留存难",
    description:
      "平台客户忠诚度低，互动性差，缺乏有效的营销玩法，触达率低，难留存",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/item/icon3.png",
    title: "转化低，复购少",
    description:
      "企业缺乏高效的客户转化方法，传统销售模式失灵，私域客户转化率低",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/item/icon4.png",
    title: "管理难，效率低",
    description: "私域运营门槛高，客户服务效率低，员工离职容易带走客户",
  },
];

const Pains = () => {
  return (
    <section
      id="Pain"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/item/bg.png')] w-full bg-cover"
    >
      <div className="container mx-auto px-4 pb-16">
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-4xl font-medium text-black/85">
            客户做私域遇到的痛点
          </h2>
        </motion.div>

        <div className="flex justify-center gap-x-6 mt-12">
          {pain.map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center w-[250px]"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <DynamicImage
                src={item.icon}
                alt="痛点"
                width={60}
                height={60}
              ></DynamicImage>
              <p className="text-xl font-medium text-black/85 mt-4">
                {item.title}
              </p>
              <p className="text-sm text-black/85 mt-2 text-center">
                {item.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pains;

import { motion } from "framer-motion";
import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";

const Bottom = () => {
  return (
    <section
      id="features"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/bottom/bg.png')] w-full bg-cover"
    >
      <div className="w-full px-[182px] py-[30px] relative">
        <DynamicImage
          src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/whiteLogo.png"
          width={142}
          height={37}
          alt="微友智汇"
        ></DynamicImage>
        <p className="text-xl font-medium text-white mt-3">
          全链路营销解决方案
        </p>
        <p className="text-base mt-1 text-white">
          实时在线解答，降低企业运营成本；强大的技术保障，高效响应客户需求
        </p>
        <div className="absolute bottom-[30px] right-[182px] w-[122px] h-9 bg-white shadow-black/5 rounded-s-sm flex justify-center items-center text-base text-themeColor font-medium">
          立即免费使用
        </div>
      </div>
    </section>
  );
};

export default Bottom;

import { motion } from "framer-motion";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import { useState } from "react";
import DynamicImage from "@/components/DynamicImage";

const features = [
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/me/icon1.png",
    title: "SCRM系统是必须的吗？",
    description:
      "私域的核心聚焦于营销运营上，且需要经验不断累积，然而这些能力在市场上处于稀缺状态。微友智汇SCRM 系统可有效的将营销各个板块进行标准化和流程化处理，为企业私域运营赋能。营销的关键要点在于找到恰当的人、相符的产品以及行之有效的触达手段，与企业适配的 SCRM 能够以标准化的方式推进私域运营落地。",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/me/icon2.png",
    title: "私域是什么？真的有效吗？",
    description:
      "私域并不仅仅局限于流量，它更是当下数字化市场中的一种全新运营思维。在当前流量成本急剧上涨以及竞品如红海般的时代，对企业私域用户进行有效的运营，能够帮助企业降低对新流量的依赖程度，提高首购率，促成复购，从而为企业实现降本增效的目标。",
  },
  {
    icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/me/icon3.png",
    title: "微友智汇的SCRM适合我们吗？",
    description:
      "微友智汇 SCRM 是一套从业务实战中积累而来的系统。我们认为只有契合企业业务需求的系统，才是有价值的系统。微友智汇 SCRM 的全场景全链路 SaaS 产品会根据企业的业务模式进行适配，并提供陪跑赋能服务，为实现企业的业务增长目标全力以赴。",
  },
];

const Features = () => {
  const [active, setActive] = useState(0);
  return (
    <section
      id="features"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/me/bg.png')] w-full bg-cover"
    >
      <div className="container mx-auto pb-16">
        <motion.div
          className="text-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl mt-16 font-medium text-black/85">
            企业对私域的疑惑
          </h2>
        </motion.div>

        <div className="flex justify-center gap-x-4 mt-[38px]">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className={`p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/me/itemBg.png')] w-[348px] bg-cover ${index + 1 === active ? "border-orange-500 border-2" : ""}`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              onMouseEnter={() => setActive(index + 1)}
            >
              <DynamicImage
                src={feature.icon}
                alt="私域"
                width={140}
                height={120}
              ></DynamicImage>
              <p className="text-xl font-medium text-black/85 mt-3">
                {feature.title}
              </p>
              <div className="h-px bg-black/5 my-4 "></div>
              <p
                className="text-sm text-black/85"
                style={{ letterSpacing: "1px" }}
              >
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;

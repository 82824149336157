import { motion } from "framer-motion";
import { div } from "framer-motion/client";
import {
  MessageSquare,
  Users,
  Shield,
  Zap,
  BarChart,
  Globe,
} from "lucide-react";
import Image from "next/image";
import DynamicImage from "@/components/DynamicImage";
import { useState } from "react";

const solution = [
  [
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon1.png",
      title: "渠道活码",
      description: "可创建带参数的客服码进行分流；可设置欢迎语、标签、统计数据",
      index: 1,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon2.png",
      title: "好友欢迎语",
      description: "可为员工配置好友欢迎语， 支持发送文字、图片及图文消息",
      index: 2,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon3.png",
      title: "客户画像",
      description:
        "可查看客户的销售、加群、标签变化等动态及客户是否打开文件， 商城购买记录等",
      index: 3,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon4.png",
      title: "删除提醒",
      description: "实时通知员工客户删除情况， 便于员工及时挽回客户",
      index: 4,
    },
  ],
  [
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon5.png",
      title: "客户SOP",
      description:
        "可创建自动化客户跟进SOP， 员工一键发送触达内容，高效服务客户",
      index: 5,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon6.png",
      title: "群发SOP",
      description: "可根据需求给社群制定不同的群发SOP计划",
      index: 6,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/icon7.png",
      title: "智能雷达",
      description:
        "智能记录客户访问动作，客户轨迹追踪+智能提醒，及时跟进重点商机",
      index: 7,
    },
    {
      icon: "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/rightAllow.png",
      title: "了解更多",
      special: true,
      index: 0,
    },
  ],
];

const Solutions = () => {
  const [active, setActive] = useState(1);
  const [isQrCode, setIsQrCode] = useState(false);
  return (
    <section
      id="Solutions"
      className="bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/bg.png')] w-full bg-cover"
    >
      <div className="container mx-auto px-4 pb-16">
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-4xl font-medium text-black/85">
            微友智汇SCRM解决方案
          </h2>
        </motion.div>

        <div className="flex justify-center flex-wrap gap-y-3 mt-12">
          {solution.map((solution, index) => (
            <div className="flex justify-center w-full gap-x-4" key={index}>
              {solution.map((item, index1) =>
                item.special ? (
                  <div
                    key={index1}
                    onMouseEnter={() => setIsQrCode(true)}
                    onMouseLeave={() => setIsQrCode(false)}
                    className={`w-[72px] h-[188px] rounded-[10px] flex flex-col relative items-center py-[30px] gap-2 ${isQrCode ? "bg-themeColor " : "bg-[url('https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/knowMoreBg.png')] bg-cover"}`}
                  >
                    <span
                      className={`font-bold ${isQrCode ? "text-white" : "text-themeColor"}`}
                      style={{
                        writingMode: "vertical-lr",
                        textOrientation: "upright",
                        letterSpacing: "4px",
                      }}
                    >
                      {item.title}
                    </span>
                    <DynamicImage
                      src={
                        isQrCode
                          ? "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/whiteRightAllow.png"
                          : "https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/rightAllow.png"
                      }
                      width={24}
                      height={24}
                      alt="查看更多解决方案"
                    ></DynamicImage>
                    {isQrCode && (
                      <div className="w-[184px] h-[236px] p-2 flex flex-wrap absolute left-20 -top-4 rounded-md bg-white">
                        <DynamicImage
                          src="https://scrm-zhuque.oss-cn-shenzhen.aliyuncs.com/staticIcon/home/solution/home/qrcode.png"
                          width={168}
                          height={168}
                          alt="微友智汇二维码"
                        ></DynamicImage>
                        <p className="mt-2 text-black/85 text-sm font-medium w-full text-center">
                          立即扫码
                        </p>
                        <p className="mt-2 text-black/85 text-sm font-medium w-full text-center">
                          获取私域运营增长方案
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <motion.div
                    key={index1}
                    className={`bg-white rounded-[10px] py-4 px-5 relative shadow-lg hover:shadow-xl transition-shadow flex flex-col items-center ${index ? "w-[319px] min-h-[188px]" : "w-[257px] min-h-[210px]"}`}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: index * 0.1 }}
                    onMouseEnter={() => setActive(item.index)}
                  >
                    {active === item.index ? (
                      <div className="absolute w-full h-1.5 rounded-t-[10px] bg-themeColor top-0"></div>
                    ) : (
                      ""
                    )}
                    <DynamicImage
                      src={item.icon}
                      alt="解决方案"
                      width={68}
                      height={68}
                    ></DynamicImage>
                    <p className="text-xl font-medium text-black/85 mt-2">
                      {item.title}
                    </p>
                    <p className="text-sm text-black/85 mt-2 text-center">
                      {item.description}
                    </p>
                  </motion.div>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions;

import Image from "next/image";
import dynamic from "next/dynamic";

const DynamicImage = ({
  src,
  alt,
  width,
  height,
  className,
}: {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
}) => {
  // const ImageComponent = dynamic(() => import("next/image"), {
  //   // loading: () => <p>Loading...</p>,
  //   ssr: false, // 禁用服务器端渲染
  // });
  return (
    <Image
      src={src}
      alt={alt || "Dynamic Image"}
      width={width || 500}
      className={className}
      height={height || 500}
      unoptimized // 启用 unoptimized 属性
    />
  );
};

export default DynamicImage;
